<template>
	<div>
		<ts-page-title :title="$t('customerStatement.pageTitle')" :breadcrumb="[
			{ text: $t('home'), href: '/' },
			{
				text: $t('customerStatement.pageTitle'),
				active: true
			}
		]" />
		<ts-panel>
			<ts-panel-wrapper>
				<div class="row">
					<div class="col-lg-3">
						<div class="card border-0 bg-gray-900">
							<div class="card-body">
								<div class="text-gray-500">
									<b>{{
										$t('customerStatement.filterCriteria')
									}}</b>
								</div>
							</div>
							<div class="widget-list rounded-bottom">
								<div class="mb-3 mt-3">
									<div class="col-md-12">
										<label class="form-col-label control-label">{{
											$t('customerStatement.branch')
										}}</label>
										<ts-branch-filter @filter="
											value =>
												(model.branch_id = value)
										" :isPlaceholder="true" />
										<div class="text-danger" v-if="errors.has('branch_id')">
											{{ errors.first('branch_id') }}
										</div>
									</div>
								</div>
								<div class="mb-3">
									<div class="col-md-12">
										<label class="form-col-label control-label">{{
											$t('customerStatement.co')
										}}</label>
										<Select v-model="model.employee_id" multiple :max-tag-count="1"
											:filterable="true" :filter-by-label="true" :placeholder="$t('all')"
											@on-change="loanDisbursement()">
											<Option v-for="item in employees" :value="item.employee_id"
												:key="item.employee_id" :label="item.employee_name_en">
												{{ item.employee_name_en }}
											</Option>
										</Select>
										<div class="invalid-feedback" v-if="errors.has('employee_id')">
											{{ errors.first('employee_id') }}
										</div>
									</div>
								</div>
								<div class="mb-3">
									<div class="col-md-12">
										<label class="form-col-label control-label required">{{
											$t(
																					'customerStatement.loanOrCustomer'
											)
										}}</label>
										<Select v-model="model.loan_id" filterable clearable multiple
											:max-tag-count="1" :filter-by-label="true" :remote-method="loanDisbursement"
											:loading="loanLoading" :placeholder="$t('select')" :class="{
												'ivu-form-item-error': errors.has(
													'loan_id'
												)
											}">
											<Option v-for="(option,
											index) in loanDisbursements" :value="option.loan_id" :key="index" :label="
		option.loan_number +
		'|' +
		option.customer
			.customer_code +
		'|' +
		option.customer
			.customer_name_en +
		'|' +
		option.customer
			.customer_name_kh
	">
												{{ option.loan_number }}
												<span v-if="option.is_finished" class="badge bg-success tw-capitalize">
													Finished </span>|
												{{
													option.customer
														.customer_code
												}}
												|
												{{
													option.customer
														.customer_name_en
												}}
												|
												{{
													option.customer
														.customer_name_kh
												}}
												|
												{{ option.customer.contact_no }}
											</Option>
										</Select>
										<div class="text-danger" v-if="errors.has('loan_id')">
											{{ errors.first('loan_id') }}
										</div>
									</div>
								</div>
								<div class="mb-3">
									<div class="col-md-12">
										<label class="form-col-label control-label required">{{
											$t(
																					'summaryOfActiveAccount.currency'
											)
										}}</label><br />
										<RadioGroup v-model="model.currency_id" size="small">
											<Radio class="mt-1" :label="c.currency_id" border
												v-for="(c, index) in currencies" :key="index">
												{{ c.currency_code }}
											</Radio>
										</RadioGroup>
										<div class="text-danger" v-if="errors.has('currency_id')">
											{{ errors.first('currency_id') }}
										</div>
									</div>
								</div>
								<div class="mb-3">
									<div class="col-md-12">
										<label class="form-col-label control-label">{{
											$t( 'dailyCollectionSchedule.excelTemplate')
										}}</label><br />
										<RadioGroup size="small" v-model="model.excel_type">
											<Radio class="mt-1" :label="c.value" border v-for="(c, index) in excel"
												:key="index">
												{{ c.label }}
											</Radio>
										</RadioGroup>
									</div>
								</div>
								<div class="mb-3 tw-justify-end tw-flex tw-space-x-2">
									<ts-button outline color="success" @click.prevent="exportExcel"
										:waiting="exporting">
										<i class="far fa-file-excel" v-if="!exporting"></i>
										{{ $t('exportExcel') }}</ts-button>
									<ts-button color="danger" outline @click.prevent="preview" :waiting="waiting">
										<i class="far fa-file-pdf" v-if="!waiting"></i>
										{{ $t('previewPdf') }}</ts-button>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-9">
						<div id="container">
							<ts-preview-pdf v-model="waiting" :src="src" />
						</div>
					</div>
				</div>
			</ts-panel-wrapper>
		</ts-panel>
	</div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'
import { debounce, join } from 'lodash'
import * as FileDownload from 'downloadjs'
import { trim } from 'lodash'

export default {
	name: 'customerStatement',
	data() {
		return {
			waiting: false,
			exporting: false,
			loanLoading: false,
			errors: new Errors(),
			src: '',
			excel: [
				{
					value: 'standard',
					label: 'Standard'
				},
				{
					value: 'raw_data',
					label: 'Raw Data'
				}
			],
			model: {
				excel_type: 'standard',
				branch_id: [],
				employee_id: [],
				loan_id: [],
				currency_id: null
			}
		}
	},
	created() {
		this.model.currency_id = this.$store.state.authUser.sys_setting[0].default_currency_id
	},
	computed: {
		...mapState('report/resource', [
			'employees',
			'currencies',
			'loanDisbursements'
		])
	},
	methods: {
		...mapActions('report/resource', [
			'getEmployee',
			'getCurrency',
			'getLoanDisbursement'
		]),
		loanDisbursement: debounce(function (query) {
			this.loanLoading = true
			this.model.loan_id = []
			if (query) {
				this.getLoanDisbursement(
					Object.assign(
						{
							filter: {
								search: query,
								co_id: join(this.model.employee_id, ","),
								approve_status: 2, //Approved
							}
						},
						this.model
					)
				).finally(() => {
					this.loanLoading = false
				})
			}
			if (!query) {
				this.getLoanDisbursement(this.model).finally(() => {
					this.loanLoading = false
				})
			}
		}, 1000),
		preview() {
			this.errors = new Errors()
			this.waiting = true
			this.src = ''
			this.$store
				.dispatch(
					'report/creditOperation/customerStatement',
					this.model
				)
				.then(response => {
					this.src = response.url
				})
				.catch(error => {
					this.notice({ type: 'error', text: error.message })
					this.errors = new Errors(error.errors)
				})
				.finally(() => {
					this.waiting = false
				})
		},
		exportExcel() {
			this.errors = new Errors()
			this.exporting = true

			if (this.model.excel_type == 'standard') {
				this.$store
					.dispatch(
						'report/creditOperation/customerStatementExportExcel',
						this.model
					)
					.then(response => {
						let filename = trim(
							response.headers['content-disposition']
								.substring(
									response.headers['content-disposition'].indexOf(
										'filename'
									)
								)
								.replace('filename=', ''),
							'"'
						)
						FileDownload(response.data, filename)
					})
					.catch(err => {
						let error = JSON.parse(
							String.fromCharCode.apply(null, new Uint8Array(err))
						)
						this.notice({ type: 'error', text: error.message })
						this.errors = new Errors(error.errors)
					})
					.finally(() => (this.exporting = false))
			}

			if (this.model.excel_type == 'raw_data') {
				this.$store
					.dispatch(
						"report/creditOperation/customerStatementRawDataCSV",
						this.model
					)
					.then(response => {
						const anchor = document.createElement('a');
						anchor.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(response.data);
						anchor.target = '_blank';
						anchor.download = 'report-customer-statement.csv';
						anchor.click();
					})
					.catch(err => {
						let error = JSON.parse(
							String.fromCharCode.apply(null, new Uint8Array(err))
						)
						this.notice({ type: 'error', text: error.message })
						this.errors = new Errors(error.errors)
					})
					.finally(() => (this.exporting = false))
			}
		},
		notice(not) {
			this.$Notice[not.type]({
				title: 'CUSTOMER STATEMENT',
				desc: not.text
			})
		}
	},
	watch: {
		'model.branch_id': debounce(function (value) {
			this.model.employee_id = []
			this.getEmployee({
				branch_id: value
			})
			this.loanDisbursement()
		}, 500)
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.getCurrency()
		})
	}
}
</script>
